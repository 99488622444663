<template>
  <div>
    <el-dialog
      title="上传文件"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <p>{{ file.name }}</p>
      <el-progress
        :percentage="percentage"
        :color="customColorMethod"
        :stroke-width="26"
        :text-inside="true"
      ></el-progress>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogVisible = false"
          :loading="btnLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      percentage: 0,
      customColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      file: {},
      btnLoading: true,
    };
  },

  methods: {
    show(percentage, cpt, nunmber) {
      console.log(percentage, cpt);
      this.dialogVisible = true;
      this.percentage = percentage;
      if (percentage >= 100) {
        this.dialogVisible = false;
        this.btnLoading = false;
        this.$parent.fileList.push({
          url: nunmber + "" + cpt.name,
          name: nunmber + "" + cpt.name,
        });
      }
      this.file = cpt;
      console.log(this.file);
    },
    handleClose() {
      if (this.percentage >= 100) {
        this.dialogVisible = false;
        this.percentage = 0;
        this.file = {};
      } else {
        this.$confirm("关闭将取消上传,确定取消吗?")
          .then((_) => {
            this.dialogVisible = false;
            this.$parent.cancelApi(this.file);
            this.percentage = 0;
            this.file = {};
            this.$message({
              message: "取消上传",
              type: "warning",
            });
          })
          .catch((_) => {});
      }
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  margin-top: 29vh;
}
p {
  margin-bottom: 20px;
  font-size: 20px;
}
</style>
