// var OSS = require('ali-oss');
import OSS from "ali-oss";
export function client() {
  var client = new OSS({
    endpoint: "oss-cn-hangzhou.aliyuncs.com", //填写Bucket所在地域
    accessKeyId: "LTAI4Fu7oz8tDC8QFXrT7Z9K",
    accessKeySecret: "7krUSyTuhX6soGkn4XDPbsyIfrRQdF",
    bucket: "rd-online", // 填写Bucket名称。
    secure: true,
  }); //后端提供数据
  return client;
}
const rules = [
  {
    // 指定允许跨域请求的来源，支持通配符星号（*），表示允许所有的来源域。
    allowedOrigin: "*",
    // 指定允许的跨域请求方法，支持GET、PUT、DELETE、POST和HEAD方法。
    allowedMethod: "GET",
    // 指定允许跨域请求的响应头。建议无特殊情况下将此项设置为通配符星号（*）。
    allowedHeader: "*",
    // 指定允许用户从应用程序中访问的响应头，例如一个JavaScript的XMLHttpRequest对象。不允许使用通配符星号（*）。
    exposeHeader: "Content-Length",
    // 指定浏览器对特定资源的预取（OPTIONS）请求返回结果的缓存时间，单位为秒。
    maxAgeSeconds: "30",
  },
];
const headers = {
  // 指定该Object被下载时的网页缓存行为。
  // "Cache-Control": "no-cache",
  // 指定该Object被下载时的名称。
  // "Content-Disposition": "example.txt",
  // 指定该Object被下载时的内容编码格式。
  "Content-Encoding": "utf-8",
  // 指定过期时间，单位为毫秒。
  // Expires: "1000",
  // 指定Object的存储类型。
  // "x-oss-storage-class": "Standard",
  // 指定Object的访问权限。
  "x-oss-object-acl": "public-read",
  // 指定Object标签，可同时设置多个标签。
  // "x-oss-tagging": "Tag1=1&Tag2=2",
  // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
  // "x-oss-forbid-overwrite": "true",
};
/**
 * 生成随机uuid
 */
export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return `${+new Date()}_${rx()}${rx()}`;
};
export { headers, rules };
